<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isSearch="false"
    >
      <template #headBtnSlot>
        <v-button text="搜索" @click="search()"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="好物标题"
          v-model="searchParam.goodsName"
          placeholder="请输入好物标题"
          clearable
        />
        <v-input
          label="好物价格"
          v-model="goodsPrice1"
          placeholder=""
          clearable
        />
        <v-input
          style="margin-left: -7px"
          label="至"
          v-model="goodsPrice2"
          placeholder=""
          clearable
        />
        <v-date-picker
          label="发布时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="发布开始时间"
          endPlaceholder="发布结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" @click="check(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      :title="title"
      v-model="show"
      @confirm="confirm"
      sureTxt="保存"
      :showCancelButton="false"
      oStyle="justify-content: flex-end;"
      @close="closeDialog"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="发布人">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.nickName"
            disabled
          ></v-input> </el-form-item
        ><el-form-item label="好物价格">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.goodsPrice"
            disabled
          ></v-input> </el-form-item
        ><el-form-item label="被收藏数量">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.collectNumber"
            disabled
          ></v-input> </el-form-item
        ><el-form-item label="标题">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.goodsName"
            disabled
          ></v-input> </el-form-item
        ><el-form-item label="内容">
          <v-input
            clearable
            type="textarea"
            class="filter-item"
            v-model="temp.goodsContent"
            disabled
          ></v-input>
        </el-form-item>
        <el-form-item label="上传照片">
          <v-upload :limit="9" :imgUrls="temp.goodsPhotoList" disabled />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  goodThingsListUrl,
  goodThingsDetailsUrl,
  goodThingsDeleteUrl,
} from "./api.js";

export default {
  name: "goodMaterialManagement",
  data() {
    return {
      proposeAnnexFiles: "",
      title: "查看",
      show: false,
      searchParam: {
        goodsName: null,
        goodsPrice: null,
        str3: null,
        time: "",
      },
      goodsPrice1: "",
      goodsPrice2: "",
      createTime: [],
      tableUrl: goodThingsListUrl,
      headers: [
        {
          prop: "goodsId",
          label: "编号",
        },
        {
          prop: "goodsName",
          label: "好物标题",
        },
        {
          prop: "goodsPrice",
          label: "好物价格（元）",
        },
        {
          prop: "createTime",
          label: "发布时间",
        },
      ],
      temp: {},
    };
  },
  watch: {
    createTime: {
      handler(n, o) {
        if (n) {
          this.searchParam.time = n.join(",");
        } else {
          this.searchParam.time = "";
        }
        console.log(this.searchParam.time);
      },
    },
  },
  mounted() {},
  methods: {
    search() {
      if (this.goodsPrice1 == "" && this.goodsPrice2 == "") {
        this.searchParam.goodsPrice = "";
        this.$refs.list.search();
      } else if (this.goodsPrice1 != "" && this.goodsPrice2 == "") {
        this.$message.error("请填写完整的好物价格区间~");
      } else if (this.goodsPrice1 == "" && this.goodsPrice2 != "") {
        this.$message.error("请填写完整的好物价格区间~");
      } else if (Number(this.goodsPrice1) > Number(this.goodsPrice2)) {
        this.searchParam.goodsPrice = `${this.goodsPrice2},${this.goodsPrice1}`;
        this.$refs.list.search();
      } else {
        this.searchParam.goodsPrice = `${this.goodsPrice1},${this.goodsPrice2}`;
        this.$refs.list.search();
      }
    },
    closeDialog() {
      console.log(123);
    },
    confirm() {
      this.show = false;
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          goodsId: item.goodsId,
        };
        this.$axios
          .get(`${goodThingsDeleteUrl}`, { params: params })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    check(item) {
      this.title = "查看";
      this.show = true;
      item.goodsPhotoList = item.goodsPhotoList.join(",");
      this.temp = item;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
