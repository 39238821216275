const goodThingsListUrl = `/gateway/hc-serve/manageapi/idleGoodThings/goodThingsList`; // 好物管理列表
const goodThingsDetailsUrl = `/gateway/hc-serve/manageapi/idleGoodThings/goodThingsDetails`; //好物详情
const goodThingsDeleteUrl = `/gateway/hc-serve/manageapi/idleGoodThings/goodThingsDelete`; //好物删除
const purchaseListUrl = `/gateway/hc-neighbour/topic/getTopicList`; //求购管理列表
const purchaseDetailsUrl = `/gateway/hc-serve/manageapi/idleGoodThings/purchaseDetails`; //求购详情
const purchaseDeleteUrl = `/gateway/hc-neighbour/topic/deleteTopic`; //求购删除
const commentListUrl = `/gateway/hc-neighbour/topic/getTopicCommentList`; //评论管理列表
const commentDeleteUrl = `/gateway/hc-neighbour/topic/deleteComment`; //评论删除
export {
  goodThingsListUrl,
  goodThingsDetailsUrl,
  goodThingsDeleteUrl,
  purchaseListUrl,
  purchaseDetailsUrl,
  purchaseDeleteUrl,
  commentListUrl,
  commentDeleteUrl,
};
